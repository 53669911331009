import { defineComponent } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
  props: {
    detail: {
      required: true,
      type: null
    },
    showAttachment: {
      required: true,
      type: String
    }
  },
  emits: ['downFile'],
  setup: function setup() {
    var baseurl = process.env.VUE_APP_BASE_URL;
    var route = useRoute();
    var id = route.query.id;
    console.log(id);
    return {
      baseurl: baseurl
    };
  }
});